<template>
  <main v-if="!isLoadingLocales">
    <section class="pagehead">
      <div class="container">
        <div class="pageheadinner">
          <h1>{{ $t("explore_information_page.learn_from_dr_figo") }}</h1>
          <img
            class="pageheadhuman"
            src="../assets/images/human5.png"
            alt="swoohuman"
          >
        </div>
      </div>
    </section>
    <section class="topicmain">
      <div class="container">
        <div class="topicmainbox toicslist">
          <h1>
            {{ $t("explore_information_page.more_about_topic") }}
            {{ topicName }}
          </h1>
          <p v-html="$t('explore_information_page.see_medically_accurate_answers')" />
          <router-link to="/learn-from-dr-figo">
            <i class="fa fa-angle-left" />
            {{ $t("explore_information_page.back_to_all_topics") }}
          </router-link>
        </div>
        <div class="row">
          <div
            class="col-md-6"
            v-for="(item, index) in faqList"
            :key="index"
          >
            <router-link
              :to="
                '/learn-from-dr-figo/' +
                  categoryId +
                  '/learn-topic/' +
                  item.id +
                  '/single-topic?topic=' +
                  topicName
              "
            >
              <div class="topicdetails">
                <h1 class="ellipsis">
                  {{ item.title }}
                </h1>
                <p
                  class="ellipsis-2"
                  v-html="item.content"
                />
                <i class="fa fa-caret-right" />
              </div>
            </router-link>
          </div>
        </div>
        <div>
          <b-pagination
            v-model="page"
            pills
            @input="onPageChange"
            :total-rows="total"
            :per-page="limit"
            first-number
            last-number
          />
        </div>
      </div>
    </section>
    <section class="searchmain">
      <img
        class="bannerhuman"
        src="../assets/images/swoosh.png"
        alt="swoosh"
      >
      <div class="searchmaininner">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <img
                class="searchhuman"
                src="../assets/images/human4.png"
                alt="human"
              >
            </div>
            <div class="col-lg-7 col-md-8">
              <div class="searchbox">
                <h1
                  v-if="isEnabled"
                  v-html="$t('explore_information_page.search_and_ask_questions')"
                />
                <div
                  v-if="isEnabled"
                  class="bannersearch-form"
                >
                  <input
                    type="text"
                    name=""
                    :placeholder="
                      $t('explore_information_page.example_can_i_get_pregnant')
                    "
                    v-model="askQuestion"
                    @keyup.enter="saveQuestion"
                  >
                  <button
                    type="button"
                    @click="saveQuestion()"
                  >
                    {{ $t("explore_information_page.search") }}
                    <i class="fa fa-search" />
                  </button>
                </div>
                <p v-if="isEnabled">
                  <i class="fa fa-play" />
                  <span v-html="$t('explore_information_page.already_answer_a_question')" />
                  <router-link to="/retrieve-an-answer">
                    {{ $t("explore_information_page.click_here_to_retrieve") }}
                  </router-link>
                </p>
                <p
                  v-if="!isEnabled"
                  class="display-6"
                >
                  <b v-html="$t('explore_information_page.welcome_to_ask_dr_figo_beta')" />{{ $t("explore_information_page.as_of_right_now") }}
                </p>
                <p v-if="!isEnabled">
                  <i class="fa fa-play" />
                  <span v-html="$t('explore_information_page.please_browse_our_question')" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="checkout checkoutsearch">
      <div class="container">
        <div class="row">
          <div class="col-md-7 offset-lg-1">
            <div class="checkouttext">
              <h1>
                {{ $t("explore_information_page.check_out_basic_information") }}
              </h1>
              <router-link to="/personal-health-basics">
                {{ $t("explore_information_page.check_out_dr_figo_basics") }}
                <i class="fa fa-caret-right" />
              </router-link>
            </div>
          </div>
          <div class="col-md-5 col-lg-4">
            <img
              class="checkouthuman"
              src="../assets/images/human3.png"
              alt="human"
            >
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isEnabled: false,
      askQuestion: "",
      categoryId: "",
      topicName: "",
      faqList: [],
      total: 0,
      page: 1,
      limit: 6,
    };
  },
  created() {},
  mounted() {
    this.categoryId = this.$route.params.categoryId;
    this.topicName = this.$route.query.topic;

    this.$eventBus.$on("initial-data-event", (data) => {
      this.isEnabled = data.is_enabled;
      this.page = 1;
      this.getFaqs();
    });

    this.$eventBus.$on("page-data-event", (data) => {
      this.page = 1;
      this.getFaqs();
    });

    if (this.hasInitialInfo) {
      this.isEnabled = this.initialInfo.is_enabled;

      this.page = 1;
      this.getFaqs();
    }
  },
  methods: {
    saveQuestion() {
      if (this.askQuestion.length > 0) {
        sessionStorage.setItem("askQuestion", this.askQuestion);
        this.$router.push("/anwser-single-topic");
      }
    },
    getFaqs() {
      let params = {
        locale: this.currentLocale,
        page_size: this.limit,
        page: this.page,
        categoryId: this.categoryId,
      };

      this.$apiService.getFaqs(params).then((res) => {
        this.faqList = res.data.data.result;
        this.total = res.data.data.total;
      });
    },
    onPageChange() {
      this.getFaqs();
    },
  },
  beforeDestroy() {
    this.$eventBus.$off("page-data-event");
  },
};
</script>
