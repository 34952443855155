<template>
  <main v-if="!isLoadingLocales">
    <section class="page sitemap">
      <div class="container">
        <div
          class="pageinner"
          v-if="isSiteMap"
        >
          <h1>{{ $t("global_page.website_site_map") }}</h1>
        </div>
        <div
          class="pageinner"
          v-if="!isSiteMap"
        >
          <h1 class="mb-4">
            {{ $t("global_page.page_not_found") }}
          </h1>
          <p>{{ $t("global_page.please_visit_our_other_pages") }}</p>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="topicmainbox">
              <ul>
                <li class="nav-item">
                  <router-link to="/">
                    {{ $t("global_page.home") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/learn-from-dr-figo">
                    {{ $t("global_page.explore_all_information") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/personal-health-basics">
                    {{ $t("global_page.see_personal_health_basics") }}
                  </router-link>
                </li>
                <li
                  class="nav-item"
                  v-if="isEnabled"
                >
                  <router-link to="/retrieve-an-answer">
                    {{ $t("global_page.retrieve_an_answer") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/about-dr-figo">
                    {{ $t("global_page.about_dr_figo") }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/emergency-help">
                    {{ $t("global_page.emergency_help_and_resources") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <img
        class="mapbtmimg"
        src="../assets/images/human11.png"
        alt="human11"
      >
      <img
        class="pageimgbtm"
        src="../assets/images/pathred.png"
        alt="pathleft"
      >
    </section>
  </main>
</template>

<script>
export default {
  name: "SiteMap",
  data() {
    return {
      isEnabled: false,
      isSiteMap: false,
    };
  },
  mounted() {
    this.$eventBus.$on("initial-data-event", (data) => {
      this.isEnabled = data.is_enabled;
    });

    if (this.hasInitialInfo) {
      this.isEnabled = this.initialInfo.is_enabled;
    }

    let currentUrl = window.location.pathname;

    this.isSiteMap = currentUrl.includes("site-map") ? true : false;
    this.onStopLoadingLocales();
  },
  watch: {
    $route: function (to, from) {
      let currentUrl = window.location.pathname;

      this.isSiteMap = currentUrl.includes("site-map") ? true : false;
    },
  },
};
</script>