var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoadingLocales)?_c('main',[_c('section',{staticClass:"pagehead"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"pageheadinner"},[_c('h1',[_vm._v(_vm._s(_vm.$t("explore_information_page.learn_from_dr_figo")))]),_c('img',{staticClass:"pageheadhuman",attrs:{"src":require("../assets/images/human5.png"),"alt":"swoohuman"}})])])]),_c('section',{staticClass:"topicmain"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-8"},[_c('div',{staticClass:"topicmainbox toicslist singletoics"},[_c('h1',[_vm._v(_vm._s(_vm.faqDetails.title))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.faqDetails.content)}}),_c('router-link',{attrs:{"to":'/learn-from-dr-figo/' +
                  _vm.categoryId +
                  '/learn-topic?topic=' +
                  _vm.topicName}},[_c('i',{staticClass:"fa fa-angle-left"}),_vm._v(" "+_vm._s(_vm.$t("explore_information_page.back_to_questions_about"))+" "+_vm._s(_vm.topicName)+" ")])],1)])])])]),_c('section',{staticClass:"topicmain othertopic"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"topicmainbox toicslist singletoics"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("explore_information_page.other_answers_you_may_find_helpful"))+" ")])]),_c('div',{staticClass:"row"},_vm._l((_vm.relatedFaqList),function(item,index){return _c('div',{key:index,staticClass:"col-md-6"},[_c('router-link',{attrs:{"to":'/learn-from-dr-figo/' +
                item.category_id +
                '/learn-topic/' +
                item.id +
                '/single-topic?topic=' +
                item.category_title}},[_c('div',{staticClass:"topicdetails"},[_c('h1',{staticClass:"ellipsis"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"ellipsis-2",domProps:{"innerHTML":_vm._s(item.content)}}),_c('i',{staticClass:"fa fa-caret-right"})])])],1)}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }