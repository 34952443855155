import Zero from "../assets/images/0.png";
import One from "../assets/images/1.png";
import Two from "../assets/images/2.png";
import Three from "../assets/images/3.png";
import Four from "../assets/images/4.png";
import Five from "../assets/images/5.png";
import Six from "../assets/images/6.png";
import Seven from "../assets/images/7.png";
import Eight from "../assets/images/8.png";
import Nine from "../assets/images/9.png";
import Blank from "../assets/images/blank.png";

import axios from "axios";

const passcodeIcon = {
	"0": Zero,
	"1": One,
	"2": Two,
	"3": Three,
	"4": Four,
	"5": Five,
	"6": Six,
	"7": Seven,
	"8": Eight,
	"9": Nine,
	Blank: Blank,
};

export default class APIService {
	getPasscodeIcon() {
		return passcodeIcon;
	}

	checkAccess() {
		return axios.get("check-access");
	}

	saveQuestion(data) {
		return axios.post("question", data);
	}

	getQuestionById(questionId) {
		return axios.get("question/" + questionId);
	}

	updateQuestion(data) {
		return axios.put("question/" + data.id, data);
	}

	retrieveAnswer(countryId, code) {
		return axios.get("retrieve-answer/" + countryId + "/" + code);
	}

	updateAnswerFeedback(data) {
		return axios.post("save-answer-feedback/" + data.id, data);
	}

	getCategories(params) {
		return axios.get("categories", { params });
	}

	getFaqs(params) {
		return axios.get("faqs", { params });
	}

	getFaqById(id, params) {
		return axios.get("faqs/" + id, { params });
	}

	getPartners(params) {
		return axios.get("partner", { params });
	}

	getPersonalCategories(locale) {
		return axios.get("config/personalhealthbasics/" + locale);
	}

	getRelatedFaqs(params) {
		return axios.get("related-faqs", { params });
	}

	getHomeFaqs(params) {
		return axios.get("home-faqs", { params });
	}

	getEmergencyResources(params) {
		return axios.get("policies/emergency", { params });
	}

	getPrivacyPolicy(params) {
		return axios.get("policies/privacy", { params });
	}

	getTermsOfUse(params) {
		return axios.get("policies/terms", { params });
	}

	getQuickExit(params) {
		return axios.get("quick-exit", { params });
	}
	
}
