<template>
  <main v-if="!isLoadingLocales">
    <section class="topicmain successnmain">
      <img
        class="pathicon"
        src="../assets/images/path.png"
        alt="path"
      >
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-10">
            <div class="pageinner mb-5">
              <h1>{{ $t("retrieve_answer_page.retrieve_an_answer") }}</h1>
            </div>
            <div class="topicmainbox questiontopic">
              <p v-html="$t('retrieve_answer_page.your_code')" />
              <div class="codebox linecodebox">
                <img
                  alt="rec"
                  v-for="(value, index) in passcode"
                  :key="index"
                  :src="passcodeIcons[value]"
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="answerRetriveInfo.answer_yet"
        >
          <div class="col-lg-8 col-md-12">
            <div class="topicmainbox questiontopic">
              <p v-html="$t('retrieve_answer_page.heres_the_answer_submitted')" />
              <h1 class="text--brand">
                {{ answerRetriveInfo.question }}
              </h1>
              <p
                class="text--brand"
                v-html="answerRetriveInfo.content"
              />
            </div>
          </div>

          <div class="col-lg-8 col-md-12">
            <p
              class="emergency-help"
              @click="sendToEmergencyPage()"
            >
              <b v-html="$t('retrieve_answer_page.if_this_is_an_emergency')" />
            </p>
          </div>

          <div class="col-md-12">
            <div class="questionform">
              <form>
                <div class="row">
                  <div class="col-md-12">
                    <label class="mb-4">{{
                      $t(
                        "retrieve_answer_page.we_hope_information_was_helpfull"
                      )
                    }}</label>
                  </div>
                  <div class="col-md-4 col-lg-4">
                    <label>{{
                      $t("retrieve_answer_page.would_you_use_ask_again")
                    }}</label>
                    <ul>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="helpful"
                          value="1"
                          v-model="answerRetriveInfo.is_recommend_friend"
                        ><span class="checkmark" /><b>
                          {{ $t("retrieve_answer_page.yes") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="helpful"
                          value="0"
                          v-model="answerRetriveInfo.is_recommend_friend"
                        ><span class="checkmark" /><b>
                          {{ $t("retrieve_answer_page.no") }}
                        </b>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <label>{{
                      $t("retrieve_answer_page.have_you_use_dr_figo")
                    }}</label>
                    <ul>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="used"
                          value="1"
                          v-model="answerRetriveInfo.is_used_figo_before"
                        ><span class="checkmark" /><b>
                          {{ $t("retrieve_answer_page.yes") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="used"
                          value="0"
                          v-model="answerRetriveInfo.is_used_figo_before"
                        ><span class="checkmark" /><b>
                          {{ $t("retrieve_answer_page.no") }}
                        </b>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4">
                    <label>{{
                      $t("retrieve_answer_page.how_did_you_hear")
                    }}</label>
                    <ul>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="hear"
                          value="Yegna"
                          v-model="answerRetriveInfo.know_about_website"
                        ><span class="checkmark" /><b>
                          {{ $t("retrieve_answer_page.yegna") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="hear"
                          value="Social media (Instagram, Facebook, and others)"
                          v-model="answerRetriveInfo.know_about_website"
                        ><span class="checkmark" /><b>
                          {{ $t("retrieve_answer_page.social_media") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="hear"
                          value="A website, like Google"
                          v-model="answerRetriveInfo.know_about_website"
                        ><span class="checkmark" /><b>
                          {{ $t("retrieve_answer_page.a_website") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="hear"
                          value="A friend"
                          v-model="answerRetriveInfo.know_about_website"
                        ><span class="checkmark" /><b>
                          {{ $t("retrieve_answer_page.a_friend") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="hear"
                          value="A healthcare professional (like a doctor) or a teacher"
                          v-model="answerRetriveInfo.know_about_website"
                        ><span class="checkmark" /><b>
                          {{
                            $t("retrieve_answer_page.a_healthcare_professional")
                          }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="hear"
                          value="Other"
                          v-model="answerRetriveInfo.know_about_website"
                        ><span class="checkmark" /><b>
                          {{ $t("retrieve_answer_page.other") }}</b>
                      </li>
                      <input
                        type="text"
                        name=""
                        v-model="answerRetriveInfo.other"
                        @keypress="preventNumberInput"
                      >
                    </ul>
                  </div>
                </div>
                <button
                  type="button"
                  @click="onSubmit()"
                >
                  {{ $t("retrieve_answer_page.submit_my_answers") }}
                  <i class="fa fa-caret-right" />
                </button>
              </form>

              <div
                class="thankyoumsg"
                v-if="successFlag"
              >
                <p v-html="$t('retrieve_answer_page.thankyou_for_answering')" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="!answerRetriveInfo.answer_yet && !answerRetriveInfo.invalid"
        >
          <div class="col-lg-12">
            <div class="topicmainbox questiontopic">
              <h1>{{ $t("retrieve_answer_page.your_answer_not_ready") }}</h1>
              <p v-html="$t('retrieve_answer_page.please_allow_additional_time')" />
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="answerRetriveInfo.invalid"
        >
          <div class="col-lg-8">
            <div class="topicmainbox questiontopic">
              <h1>
                {{ $t("retrieve_answer_page.we_are_sorry_cannot_answered") }}
              </h1>
              <p v-html="$t('retrieve_answer_page.please_ask_question_again')" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="checkout curvebg"
      v-if="!answerRetriveInfo.answer_yet"
    >
      <img
        class="swooshimg"
        src="../assets/images/curve.png"
        alt="curve"
      >
      <div class="searchmaininner">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <div class="checkouttext">
                <h1>
                  {{ $t("retrieve_answer_page.check_out_basic_information") }}
                </h1>
                <router-link to="/personal-health-basics">
                  {{ $t("retrieve_answer_page.check_out_dr_figo_basics") }}
                  <i class="fa fa-caret-right" />
                </router-link>
              </div>
            </div>
            <div class="col-md-5">
              <img
                class="checkouthuman m-0"
                src="../assets/images/human3.png"
                alt="human"
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      passcodeIcons: [],
      answerRetriveInfo: {},
      passcode: [],
      successFlag: false,
    };
  },
  methods: {
    onSubmit() {
      this.successFlag = false;

      if (
        this.answerRetriveInfo.is_recommend_friend == null &&
        this.answerRetriveInfo.is_used_figo_before == null &&
        this.answerRetriveInfo.know_about_website == null
      ) {
        return false;
      }

      if (
        this.answerRetriveInfo.know_about_website == "Other" &&
        (this.answerRetriveInfo.other == undefined ||
          this.answerRetriveInfo.other == "")
      ) {
        return false;
      }

      if (
        this.answerRetriveInfo.other &&
        this.answerRetriveInfo.know_about_website != "Other"
      ) {
        return false;
      }

      this.$apiService
        .updateAnswerFeedback(this.answerRetriveInfo)
        .then((res) => {
          this.successFlag = true;
          sessionStorage.setItem(
            "retrievePasscode",
            JSON.stringify(this.answerRetriveInfo)
          );
        });
    },
    preventNumberInput(e) {
      let keyCode = e.keyCode ? e.keyCode : e.which;

      if ((keyCode > 47 && keyCode < 58) || keyCode == 64) {
        e.preventDefault();
      }
    },
    sendToEmergencyPage() {
      this.$router.push("/emergency-help");
    },
  },
  mounted() {
    this.passcodeIcons = this.$apiService.getPasscodeIcon();
    this.answerRetriveInfo = JSON.parse(
      sessionStorage.getItem("retrievePasscode")
    );
    this.passcode = this.answerRetriveInfo.passcode.toUpperCase().split("");
  },
  destroyed() {
    sessionStorage.removeItem("retrievePasscode");
  },
};
</script>

<style scoped>
.emergency-help {
  color: rgb(240, 82, 52);
  text-decoration: underline;
  cursor: pointer;
}
</style>
