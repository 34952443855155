<template>
  <main v-if="!isLoadingLocales">
    <section class="pagehead">
      <div class="container">
        <div class="pageheadinner">
          <h1>{{ $t("explore_information_page.learn_from_dr_figo") }}</h1>
          <img
            class="pageheadhuman"
            src="../assets/images/human5.png"
            alt="swoohuman"
          >
        </div>
      </div>
    </section>
    <section class="topicmain">
      <div class="container">
        <div class="topicmainbox">
          <h1>
            {{ $t("explore_information_page.explore_frequently_asked_topics") }}
          </h1>
          <p v-html="$t('explore_information_page.our_health_guides')" />
        </div>
        <div class="row">
          <div
            class="col-md-6 col-lg-4"
            v-for="(item, index) in categories"
            :key="index"
          >
            <router-link :to="'/learn-from-dr-figo/' + item.id + '/learn-topic?topic='+item.title">
              <div class="topicbox">
                <h1 class="ellipsis">
                  {{ item.title }}
                </h1>
                <p
                  class="ellipsis-2"
                  v-html="item.description"
                />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="searchmain">
      <img
        class="bannerhuman"
        src="../assets/images/swoosh.png"
        alt="swoosh"
      >
      <div class="searchmaininner">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <img
                class="searchhuman"
                src="../assets/images/human4.png"
                alt="human"
              >
            </div>
            <div class="col-lg-7 col-md-8">
              <div class="searchbox">
                <h1 v-if="isEnabled">
                  {{ $t("explore_information_page.search_and_ask_questions") }}
                </h1>
                <div
                  v-if="isEnabled"
                  class="bannersearch-form"
                >
                  <input
                    type="text"
                    name=""
                    :placeholder="$t('explore_information_page.example_can_i_get_pregnant')"
                    v-model="askQuestion"
                    @keyup.enter="saveQuestion"
                  >
                  <button
                    type="button"
                    @click="saveQuestion()"
                  >
                    {{ $t("explore_information_page.search") }}
                    <i class="fa fa-search" />
                  </button>
                </div>
                <p v-if="isEnabled">
                  <i class="fa fa-play" />
                  <span v-html="$t('explore_information_page.already_answer_a_question')" />
                  <router-link to="/retrieve-an-answer">
                    {{ $t("explore_information_page.click_here_to_retrieve") }}
                  </router-link>
                </p>
                <p
                  v-if="!isEnabled"
                  class="display-6"
                >
                  <b v-html="$t('explore_information_page.welcome_to_ask_dr_figo_beta')" />{{ $t("explore_information_page.as_of_right_now") }}
                </p>
                <p v-if="!isEnabled">
                  <i class="fa fa-play" />
                  {{
                    $t("explore_information_page.please_browse_our_question")
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="checkout checkoutsearch">
      <div class="container">
        <div class="row">
          <div class="col-md-7 offset-lg-1">
            <div class="checkouttext">
              <h1>
                {{ $t("explore_information_page.check_out_basic_information") }}
              </h1>
              <router-link to="/personal-health-basics">
                {{ $t("explore_information_page.check_out_dr_figo_basics") }}
                <i class="fa fa-caret-right" />
              </router-link>
            </div>
          </div>
          <div class="col-md-5 col-lg-4">
            <img
              class="checkouthuman"
              src="../assets/images/human3.png"
              alt="human"
            >
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isEnabled: false,
      askQuestion: "",
      categories: [],
    };
  },
  created() {},
  mounted() {
    this.$eventBus.$on("initial-data-event", (data) => {
      this.isEnabled = data.is_enabled;
      this.getCategory();
    });

    this.$eventBus.$on("page-data-event", (data) => {
      this.getCategory();
    });

    if (this.hasInitialInfo) {
      this.isEnabled = this.initialInfo.is_enabled;
      this.getCategory();
    }
  },
  methods: {
    saveQuestion() {
      if (this.askQuestion.length > 0) {
        sessionStorage.setItem("askQuestion", this.askQuestion);
        this.$router.push("/anwser-single-topic");
      }
    },
    getCategory() {
      this.$apiService
        .getCategories({
          locale: this.currentLocale,
        })
        .then((res) => {
          this.categories = res.data.data;
        });
    },
  },
  beforeDestroy() {
    this.$eventBus.$off("page-data-event");
  },
};
</script>
