import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueAnalytics from "vue-analytics";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Vidle from 'v-idle';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vidle);

Vue.config.productionTip = false;

import axios from "axios";
import i18n from "./i18n";
axios.defaults.baseURL = process.env.VUE_APP_API_URI;

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import APIService from "./services/apiService";
import { EventBus } from "./services/event-bus";
Vue.use(Loading, {
	color: "#ec5134",
	opacity: 1,
	backgroundColor: "#fffbf4",
	idleTime: 3000, // 3 seconds
    startAtIdle: false
});

//and then use it in main.js
Vue.use(VueAnalytics, {
	id: "G-T6EWRFLT2G",
	router,
});

// Add global properties
Vue.prototype.$apiService = new APIService();
Vue.prototype.$eventBus = EventBus;
Vue.prototype.isLoadingLocales = true;

Vue.mixin({
	data() {
		return {
			initialInfo: {},
			isLoadingLocales: true,
		};
	},
	created() {
		this.$eventBus.$on("loading-locales", (value) => {
			this.$nextTick(() => {
				this.isLoadingLocales = value;
			});
		});
		this.$eventBus.$on("set-initial-info", (value) => {
			this.setInitialInfo(value);
		});
	},
	mounted() {
		this.loadInitialInfo();
	},
	computed: {
		i18n() {
			return {
				locale: this.$i18n.locale,
				messages: this.$i18n.messages,
				locales: this.$i18n.availableLocales
			};
		},
		currentLocale() {
			return ((this.initialInfo || {}).currentLanguage || {}).locale || 'en';
		},
		hasInitialInfo() {
			return this.initialInfo instanceof Object;
		},
		isIdle() {
			alert(this.$store.state.idleVue.isIdle);
			return this.$store.state.idleVue.isIdle;
		}
	},
	methods: {
		onStopLoadingLocales() {
			this.$nextTick(() => {
				this.isLoadingLocales = false;
			});
		},
		onStartLoadingLocales() {
			this.$nextTick(() => {
				this.isLoadingLocales = true;
			});
		},
		setInitialInfo(info) {
			if(info) {
				this.initialInfo = info;
				axios.defaults.headers['X-Locale'] = this.currentLocale;
				sessionStorage.setItem("initialInfo", JSON.stringify(info));
			}
		},
		loadInitialInfo() {
			const initialInfoFromSession = sessionStorage.getItem("initialInfo");
			this.initialInfo = initialInfoFromSession
				? JSON.parse(initialInfoFromSession)
				: {
					currentLanguage: { locale: "en" },
				};
		}
	},
});

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
	created() {
		// Set the demo mode header
		const urlParams = new URLSearchParams(window.location.search);
		if (
			urlParams.has("demo-mode") ||
			sessionStorage.getItem("demo-mode") != undefined
		) {
			sessionStorage.setItem("demo-mode", true);
			sessionStorage.setItem("demo-country", urlParams.get("country") || "US");
			axios.defaults.headers["X-Demo-Mode"] = true;
			axios.defaults.headers["X-Demo-Country"] =
				urlParams.get("country") || "US";
		}
	},
}).$mount("#app");
