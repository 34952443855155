import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue";
import Learn from "../views/Learn.vue";
import RetrieveAnswer from "../views/RetrieveAnswer.vue";
import About from "../views/About.vue";
import Emergency from "../views/Emergency.vue";
import PersonalHealth from "../views/PersonalHealth.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Terms from "../views/Terms.vue";
import SiteMap from "../views/SiteMap.vue";
import LearnTopic from "../views/LearnTopic.vue";
import SingleTopic from "../views/SingleTopic.vue";
import Retrieve from "../views/Retrieve.vue";
import AnwserSingleTopic from "../views/AnwserSingleTopic.vue";
import Success from "../views/Success.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/learn-from-dr-figo',
    name: 'Learn From Dr Figo',
    component: Learn
  },
  {
    path: '/learn-from-dr-figo/:categoryId/learn-topic',
    name: 'Learn From Dr Figo',
    component: LearnTopic
  },
  {
    path: '/learn-from-dr-figo/:categoryId/learn-topic/:faqId/single-topic',
    name: 'Learn From Dr Figo',
    component: SingleTopic
  },
  {
    path: '/retrieve-an-answer',
    name: 'Retrieve An Answer',
    component: RetrieveAnswer
  },
  {
    path: '/about-dr-figo',
    name: 'About Dr Figo',
    component: About
  },
  {
    path: '/emergency-help',
    name: 'Emergency Help',
    component: Emergency
  },
  {
    path: '/personal-health-basics',
    name: 'Personal Health Basics',
    component: PersonalHealth
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-of-use',
    name: 'Terms Of Use',
    component: Terms
  },
  {
    path: '/site-map',
    name: 'Site Map',
    component: SiteMap
  },
  {
    path: '/retrieve',
    name: 'Retrieve',
    component: Retrieve
  },
  {
    path: '/anwser-single-topic',
    name: 'Anwser Single Topic',
    component: AnwserSingleTopic
  },
  {
    path: '/success/:questionId',
    name: 'Success',
    component: Success
  },
  {
    path: '/page-not-found',
    name: 'Page Not Found',
    component: SiteMap
  },
  {
    path: '/redirect-page/:countryId',
    name: 'redirectPage',
  },
  { path: '*', redirect: '/page-not-found' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {

    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
export default router
