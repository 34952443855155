<template>
  <main v-if="!isLoadingLocales">
    <section class="page emergency">
      <div class="container">
        <div class="pageinner">
          <h1>{{ $t("emergency_help_page.emergency_help") }}</h1>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-8">
            <div class="topicmainbox">
              <h1>
                {{ $t("emergency_help_page.emergency_help_description_line1") }}
              </h1>
              <p v-html="$t('emergency_help_page.emergency_help_description_line2')" />
              <p v-html="$t('emergency_help_page.additional_resources_here')" />
              <!-- <div
                v-html="$t('emergency_help_page.additional_resources_content')"
              /> -->
              <VueFaqAccordion
                v-if="emergencyResources.length > 0"
                :items="emergencyResources"
                active-color="#53bbb4"
              />
            </div>
          </div>
        </div>
      </div>

      <img
        class="helphuman"
        src="../assets/images/human101.png"
        alt="human"
      >
    </section>
  </main>
</template>

<script>
import VueFaqAccordion from "vue-faq-accordion";

export default {
  components: {
    VueFaqAccordion,
  },
  data() {
    return {
      emergencyResources: [],
    };
  },
  mounted() {
    this.$eventBus.$on("initial-data-event", (data) => {
      this.getEmergencyResources();
    });

    this.$eventBus.$on("page-data-event", (data) => {
      this.getEmergencyResources();
    });

    if (this.hasInitialInfo) {
      this.getEmergencyResources();
    }
  },
  methods: {
    getEmergencyResources() {
      this.$apiService
        .getEmergencyResources({
          countryId: this.initialInfo.currentLanguage.id,
        })
        .then((res) => {
          if (res.data.data instanceof Object) {
            this.emergencyResources = res.data.data.content.map((val) => {
              return {
                title: val.attributes.title,
                value: val.attributes.content,
              };
            });
          } else {
            this.emergencyResources = [];
          }
          this.onStopLoadingLocales();
        });
    },
  },
  beforeDestroy() {
    this.$eventBus.$off("page-data-event");
  },
};
</script>
