<template>
  <div
    v-if="(attributes instanceof Object)"
    class="checkouttext"
  >
    <h1>{{ ((attributes || {}).category || {}).title }}</h1>
    <p v-html="attributes.description" />
    <template v-for="(link, linkIndex) in links">
      <router-link
        v-if="(link.attributes instanceof Object)"
        class="pull-right"
        :key="linkIndex"
        :to="getUrl(link.attributes.category)"
      >
        <template v-if="link.attributes.text">
          {{ link.attributes.text }}
        </template>
        <template v-else>
          See more {{ link.category instanceof Object ? 'about '+((link.attributes.category || {}).title || '') : '' }}
        </template> <i class="fa fa-caret-right" />
      </router-link>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    links() {
      return this.item.attributes instanceof Object
        ? this.item.attributes.attributes.links || []
        : [];
    },
    attributes() {
      return (this.item.attributes || {}).attributes || this.item.attributes;
    },
  },
  methods: {
    getUrl(category) {
      return (
        "/learn-from-dr-figo/" +
        (category instanceof Object ? category.id : category) +
        "/learn-topic?topic=" +
        (category instanceof Object ? category.slug : category)
      );
    },
  },
};
</script>