<template>
  <main v-if="!isLoadingLocales">
    <section class="pagehead">
      <div class="container">
        <div class="pageheadinner">
          <h1>{{ $t("explore_information_page.learn_from_dr_figo") }}</h1>
          <img
            class="pageheadhuman"
            src="../assets/images/human5.png"
            alt="swoohuman"
          >
        </div>
      </div>
    </section>
    <section class="topicmain">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-8">
            <div class="topicmainbox toicslist singletoics">
              <h1>{{ faqDetails.title }}</h1>
              <p v-html="faqDetails.content" />
              <router-link
                :to="
                  '/learn-from-dr-figo/' +
                    categoryId +
                    '/learn-topic?topic=' +
                    topicName
                "
              >
                <i class="fa fa-angle-left" />
                {{ $t("explore_information_page.back_to_questions_about") }}
                {{ topicName }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="topicmain othertopic">
      <div class="container">
        <div class="topicmainbox toicslist singletoics">
          <h1>
            {{
              $t("explore_information_page.other_answers_you_may_find_helpful")
            }}
          </h1>
        </div>
        <div class="row">
          <div
            class="col-md-6"
            v-for="(item, index) in relatedFaqList"
            :key="index"
          >
            <router-link
              :to="
                '/learn-from-dr-figo/' +
                  item.category_id +
                  '/learn-topic/' +
                  item.id +
                  '/single-topic?topic=' +
                  item.category_title
              "
            >
              <div class="topicdetails">
                <h1 class="ellipsis">
                  {{ item.title }}
                </h1>
                <p
                  class="ellipsis-2"
                  v-html="item.content"
                />
                <i class="fa fa-caret-right" />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      categoryId: "",
      faqId: "",
      faqDetails: {},
      relatedFaqList: [],
    };
  },
  created() {},
  mounted() {
    this.categoryId = this.$route.params.categoryId;
    this.faqId = this.$route.params.faqId;
    this.topicName = this.$route.query.topic;

    this.$eventBus.$on("initial-data-event", (data) => {
      this.getFaqById();
    });

    this.$eventBus.$on("page-data-event", (data) => {
      this.getFaqById();
    });

    if (this.hasInitialInfo) {
      this.getFaqById();
    }
  },
  methods: {
    getFaqById() {
      let params = {
        locale: this.currentLocale,
      };

      this.$apiService.getFaqById(this.faqId, params).then((res) => {
        this.faqDetails = res.data.data;
        this.getRelatedFaqs();
      });
    },
    getRelatedFaqs() {
      let params = {
        category_limit: 2,
        faq_id: this.faqDetails.id,
        question: this.faqDetails.title,
      };

      this.$apiService.getRelatedFaqs(params).then((res) => {
        this.relatedFaqList = res.data.data;
      });
    },
  },
  watch: {
    $route: function (to, from) {
      this.categoryId = this.$route.params.categoryId;
      this.faqId = this.$route.params.faqId;
      this.topicName = this.$route.query.topic;

      this.getFaqById();
    },
  },
  beforeDestroy() {
    this.$eventBus.$off("page-data-event");
  },
};
</script>
