<template>
  <main v-if="!isLoadingLocales">
    <section class="topicmain successnmain">
      <img
        class="formhuman"
        src="../assets/images/human2.png"
        alt="swoohuman"
      >
      <div class="container">
        <div class="pageinner mb-5">
          <h1>{{ $t("personal_health_basics.personal_health_basics") }}</h1>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-10">
            <div class="topicmainbox questiontopic">
              <h1>{{ $t("personal_health_basics.what_should_i_know") }}</h1>
              <div v-html="$t('personal_health_basics.what_should_i_know_description')" />
              <p>{{ $t("personal_health_basics.here_are_few_topics") }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <template v-if="categories.length">
      <section class="checkout nobg checkout-0">
        <div class="searchmaininner">
          <div class="container">
            <div class="row personalbox">
              <div
                class="col-md-6"
                v-if="categorySection1[0].show"
              >
                <PersonalHealthCheckOut
                  align="pull-right"
                  :item="categorySection1[0]"
                />
              </div>
              <div
                class="col-md-6"
                v-if="categorySection1[1].show"
              >
                <PersonalHealthCheckOut
                  align="pull-right"
                  :item="categorySection1[1]"
                />
              </div>
            </div>
          </div>
        </div>
        <img
          class="swooshimg"
          src="../assets/images/bgperson.png"
          alt="swoosh"
        >
      </section>

      <section class="checkout personalboxbg checkout-1">
        <div class="container perbg">
          <img
            class="swooshimg"
            src="../assets/images/human7.png"
            alt="swoosh"
          >
          <div class="row personalbox">
            <div
              class="col-md-12"
              v-if="categorySection1[2].show"
            >
              <PersonalHealthCheckOut
                align="pull-right"
                :item="categorySection1[2]"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="checkout personalboxbg checkout-2">
        <div class="container">
          <div class="row personalbox">
            <div
              class="col-md-6"
              v-if="categorySection1[3].show"
            >
              <PersonalHealthCheckOut
                align="pull-right"
                :item="categorySection1[3]"
              />
            </div>
            <div
              class="col-md-6"
              v-if="categorySection1[4].show"
            >
              <PersonalHealthCheckOut
                align="pull-right"
                :item="categorySection1[4]"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="checkout personalboxbg checkout-3">
        <div class="container">
          <div class="row personalbox">
            <div class="col-sm-6">
              <PersonalHealthCheckOut
                align="pull-right"
                :item="categorySection1[5]"
              />
            </div>

            <div class="col-md-6">
              <img
                class="formhuman"
                src="../assets/images/human13.png"
                alt="swoohuman"
              >
            </div>
          </div>
        </div>
      </section>

      <section
        class="checkout personalbg checkout-4"
        style="min-height: 500px"
      >
        <div class="container">
          <div class="row personalbox">
            <div
              class="col-md-6"
              v-if="categorySection1[6].show"
            >
              <PersonalHealthCheckOut
                align="pull-right"
                :item="categorySection1[6]"
              />
            </div>
            <div
              class="col-md-6"
              v-if="categorySection1[7].show"
            >
              <PersonalHealthCheckOut
                align="pull-right"
                :item="categorySection1[7]"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="checkout personalboxbg mb-5 checkout-5">
        <div class="container pb-5">
          <div class="row personalbox">
            <div
              class="col-md-6"
              v-for="(item, index) in categorySection2"
              :key="index"
            >
              <PersonalHealthCheckOut :item="item" />
            </div>
          </div>
        </div>
      </section>
    </template>
  </main>
</template>

<script>
import PersonalHealthCheckOut from "../components/PersonalHealthCheckOut";
export default {
  components: { PersonalHealthCheckOut },
  data() {
    return {
      categories: [],
    };
  },
  computed: {
    categorySection1() {
      return this.categories.length >= 8
        ? this.categories.slice(0, 8).map((category) => {
            category.show = true;
            return category;
          })
        : [...Array(8).keys()].map((i) => {
            return {
              show: false,
            };
          });
    },
    categorySection2() {
      return this.categories.length > 8
        ? this.categories.slice(8).map((category) => {
            category.show = true;
            return category;
          })
        : [];
    },
  },
  created() {},
  mounted() {
    this.$eventBus.$on("initial-data-event", (data) => {
      this.getPersonalCategories();
    });

    this.$eventBus.$on("page-data-event", (data) => {
      this.getPersonalCategories();
    });

    if (this.hasInitialInfo) {
      this.getPersonalCategories();
    }
  },
  methods: {
    getPersonalCategories() {
      this.categories = [];
      this.$apiService.getPersonalCategories(this.currentLocale).then((res) => {
        this.categories = res.data.data.categories;
      });
    },
  },
  beforeDestroy() {
    this.$eventBus.$off("page-data-event");
  },
};
</script>
