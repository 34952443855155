<template>
  <main v-if="!isLoadingLocales">
    <section class="page">
      <img
        class="pageimg"
        src="../assets/images/pathleft.png"
        alt="pathleft"
      >
      <div class="container">
        <div class="pageinner">
          <h1>{{ termsOfUse.title || 'Terms of Use' }}</h1>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div
              class="topicmainbox"
              v-html="termsOfUse.content"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      termsOfUse: {
        content: "Loading ...",
      },
    };
  },
  mounted() {
    this.$eventBus.$on("initial-data-event", (data) => {
      this.getTermsOfUse();
    });

    this.$eventBus.$on("page-data-event", (data) => {
      this.getTermsOfUse();
    });

    if (this.hasInitialInfo) {
      this.getTermsOfUse();
    }
  },
  methods: {
    getTermsOfUse() {
      this.onStartLoadingLocales();
      this.$apiService
        .getTermsOfUse({
          countryId: this.initialInfo.currentLanguage.id,
        })
        .then((res) => {
          if (res.data.data) this.termsOfUse = res.data.data;
          this.onStopLoadingLocales();
        });
    },
  },
  beforeDestroy() {
    this.$eventBus.$off("page-data-event");
  },
};
</script>