<template>
  <main v-if="!isLoadingLocales">
    <section class="topicmain successnmain">
      <img
        class="formhuman"
        src="../assets/images/human2.png"
        alt="swoohuman"
      >
      <div class="container">
        <div class="pageinner">
          <h1>{{ $t("submit_question_page.success") }}</h1>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-10">
            <div class="topicmainbox questiontopic">
              <p>
                {{ $t("submit_question_page.your_question_been_submitted") }}
              </p>
            </div>
            <div class="questionform">
              <form :disabled="isSubmitting">
                <h3>{{ $t("submit_question_page.your_code_is") }}</h3>
                <div class="codebox">
                  <img
                    alt="rec"
                    v-for="(value, index) in passcode"
                    :key="index"
                    :src="passcodeIcons[value]"
                  >
                </div>

                <p>
                  {{ $t("submit_question_page.be_sure_to_write_it_down") }}
                </p>
                <p
                  class="emergency-help"
                  @click="sendToEmergencyPage()"
                >
                  <b>{{
                    $t("submit_question_page.if_this_is_an_emergency")
                  }}</b>
                </p>
                <div class="row mt-5">
                  <div class="col-md-12 col-lg-10">
                    <p>
                      <b class="textgray">{{
                        $t("submit_question_page.it_would_be_helpful_for_us")
                      }}
                      </b>
                    </p>
                    <p v-html="$t('submit_question_page.we_would_be_very_greatful')" />
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-6 col-lg-4">
                    <label>{{
                      $t("submit_question_page.how_much_education")
                    }}</label>
                    <ul>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="education"
                          value="I haven’t ever been to school"
                          v-model="updateQuestion.education_value"
                        ><span class="checkmark" /><b>{{
                          $t("submit_question_page.i_have_not_school")
                        }}</b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="education"
                          value="Elementary or primary school"
                          v-model="updateQuestion.education_value"
                        ><span class="checkmark" /><b>
                          {{
                            $t("submit_question_page.elementary_primary_school")
                          }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="education"
                          value="Secondary or high school"
                          v-model="updateQuestion.education_value"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.secondary_high_school") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="education"
                          value="College or university"
                          v-model="updateQuestion.education_value"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.college_or_university") }}
                        </b>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <label>{{
                             $t("submit_question_page.seen_a_women’s_health_doctor")
                           }}
                      <small>{{
                        $t("submit_question_page.they_sometimes_ob_gyns")
                      }}</small></label>

                    <ul>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="seen"
                          value="Yes"
                          v-model="updateQuestion.ever_seen_doctor"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.yes") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="seen"
                          value="No"
                          v-model="updateQuestion.ever_seen_doctor"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.no") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="seen"
                          value="This doesn’t apply to me"
                          v-model="updateQuestion.ever_seen_doctor"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.this_does_not_apply") }}
                        </b>
                      </li>
                    </ul>
                  </div>

                  <div class="col-md-6 col-lg-4">
                    <label>{{ $t("submit_question_page.are_u_in_relationship") }}
                      <small>{{
                        $t(
                          "submit_question_page.this_can_include_bf_gf_married"
                        )
                      }}</small></label>

                    <ul>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="relationship"
                          value="1"
                          v-model="updateQuestion.in_relationship"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.yes") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="relationship"
                          value="0"
                          v-model="updateQuestion.in_relationship"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.no") }}
                        </b>
                      </li>
                    </ul>
                  </div>
                </div>

                <button
                  type="button"
                  :disabled="isSubmitting"
                  @click="onSubmit()"
                >
                  {{ $t("submit_question_page.submit_my_answer") }}
                  <i class="fa fa-caret-right" />
                </button>
              </form>
              <div
                class="thankyoumsg"
                v-if="successFlag"
              >
                <b-modal
                  v-model="successFlag"
                  centered
                  hide-header
                  ok-only
                  ok-variant="brand"
                  body-class="brand-background-color"
                >
                  <p v-html="$t('submit_question_page.thankyou_for_answering_questions')" />
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="checkout nobg">
      <img
        class="swooshimg"
        src="../assets/images/swoosh.png"
        alt="swoosh"
      >
      <div class="searchmaininner">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <div class="checkouttext">
                <h1>
                  {{ $t("submit_question_page.check_out_basic_information") }}
                </h1>
                <router-link to="/personal-health-basics">
                  {{ $t("submit_question_page.check_out_dr_figo_basics") }}
                  <i class="fa fa-caret-right" />
                </router-link>
              </div>
            </div>
            <div class="col-md-5">
              <img
                class="checkouthuman m-0"
                src="../assets/images/human3.png"
                alt="human"
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isSubmitting: false,
      questionId: "",
      updateQuestion: {},
      successFlag: false,
      passcodeIcons: [],
      passcode: [],
    };
  },
  mounted() {
    this.questionId = this.$route.params.questionId;
    this.getQuestionById();

    this.passcodeIcons = this.$apiService.getPasscodeIcon();
  },
  methods: {
    getQuestionById() {
      this.$apiService
        .getQuestionById(this.questionId)
        .then((response) => {
          let res = response.data;
          if (res.success) {
            this.updateQuestion = res.data;
            this.passcode = this.updateQuestion.passcode
              .toUpperCase()
              .split("");
            this.updateQuestion.user_country = parseInt(
              this.updateQuestion.user_country
            );
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    onSubmit() {
      this.successFlag = false;

      if (
        this.updateQuestion.education_value == null &&
        this.updateQuestion.ever_seen_doctor == null &&
        this.updateQuestion.in_relationship == null
      ) {
        return false;
      }

      this.isSubmitting = true;

      this.$apiService.updateQuestion(this.updateQuestion).then((res) => {
        if (res.data.success) {
          this.successFlag = true;
          this.updateQuestion = {};
        }
      });
    },
    sendToEmergencyPage() {
      this.$router.push("/emergency-help");
    },
  },
};
</script>

<style scoped>
.emergency-help {
  color: rgb(240, 82, 52);
  text-decoration: underline;
  cursor: pointer;
}
</style>
