<template>
  <div
    id="app"
    :dir="direction"
  >
    <Header
      :language="language"
      :language-list="languageList"
      @languageSelected="getlanguageSelected"
    />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      direction: "",
      language: {},
      languageList: [],
    };
  },
  created() {
    sessionStorage.removeItem("initialInfo");
    this.$eventBus.$emit("initial-data-event");
    this.getUserIp();
  },
  methods: {
    getUserIp() {
      this.$apiService
        .checkAccess()
        .then((response) => {
          if (response.status == 200) {
            let res = response.data;
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].direction =
                res.data[i].direction == null ? "ltr" : res.data[i].direction;
            }

            let info = {
              gender_has_other_option: true,
            };

            info.language = res.data.sort((a, b) =>
              a.language.localeCompare(b.language)
            );

            function setDefaultLanguage(info) {
              // Select the first english language for the current country if no language is set
              info.currentLanguage =
                (info.language || []).length > 0
                  ? info.language.find((l) => l.locale.startsWith("en")) ||
                    info.language[0]
                  : { locale: "en", direction: "ltr" };
              info.is_enabled =
                info.language.length > 0
                  ? info.currentLanguage.is_enabled
                  : false;
              return info;
            }
            info = setDefaultLanguage(info);

            this.language = info.currentLanguage;
            this.direction = info.currentLanguage.direction;
            this.languageList = info.language;

            if (this.direction == "ltr") {
              document.getElementById("app").classList.remove("rtl");
            } else {
              document.getElementById("app").classList.add("rtl");
            }

            this.$eventBus.$emit("set-initial-info", info);
            this.$eventBus.$emit("initial-data-event", info);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getlanguageSelected(lan) {
      this.language = lan;
      this.direction = lan.direction;

      this.initialInfo.currentLanguage = lan;
      this.$eventBus.$emit("set-initial-info", this.initialInfo);
      this.$eventBus.$emit("initial-data-event", this.initialInfo);
      this.$eventBus.$emit("page-data-event", true);
      this.$eventBus.$emit("page-i18n-data-event", true);
      if (lan.direction == "ltr") {
        document.getElementById("app").classList.remove("rtl");
      } else {
        document.getElementById("app").classList.add("rtl");
      }
    },
  },
  watch: {
    $route(to, from) {
      this.$eventBus.$emit("page-i18n-data-event", true);
    },
  },
};
</script>

<style>
@import "./assets/css/style.css";
</style>
