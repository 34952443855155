<template>
  <main v-if="!isLoadingLocales">
    <section class="page retrievemain">
      <div class="container">
        <div class="pageinner">
          <h1>{{ $t("retrieve_answer_page.retrieve_an_answer") }}</h1>

          <p v-html="$t('retrieve_answer_page.please_input_the_code')" />
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="retrievebox">
              <div class="allshape">
                <span>
                  <img
                    src="../assets/images/0.png"
                    alt="rec"
                    @click="codeSelected('0')"
                    :class="codeIncludes('0') ? 'add' : ''"
                  >
                  <img
                    src="../assets/images/1.png"
                    alt="rec"
                    @click="codeSelected('1')"
                    :class="codeIncludes('1') ? 'add' : ''"
                  >
                  <img
                    src="../assets/images/2.png"
                    alt="rec"
                    @click="codeSelected('2')"
                    :class="codeIncludes('2') ? 'add' : ''"
                  >
                </span>
                <span>
                  <img
                    src="../assets/images/3.png"
                    alt="rec"
                    @click="codeSelected('3')"
                    :class="codeIncludes('3') ? 'add' : ''"
                  >
                  <img
                    src="../assets/images/4.png"
                    alt="rec"
                    @click="codeSelected('4')"
                    :class="codeIncludes('4') ? 'add' : ''"
                  >
                  <img
                    src="../assets/images/5.png"
                    alt="rec"
                    @click="codeSelected('5')"
                    :class="codeIncludes('5') ? 'add' : ''"
                  >
                </span>
                <span>
                  <img
                    src="../assets/images/6.png"
                    alt="rec"
                    @click="codeSelected('6')"
                    :class="codeIncludes('6') ? 'add' : ''"
                  >
                  <img
                    src="../assets/images/7.png"
                    alt="rec"
                    @click="codeSelected('7')"
                    :class="codeIncludes('7') ? 'add' : ''"
                  >
                  <img
                    src="../assets/images/8.png"
                    alt="rec"
                    @click="codeSelected('8')"
                    :class="codeIncludes('8') ? 'add' : ''"
                  >
                </span>
                <span>
                  <img
                    src="../assets/images/9.png"
                    alt="rec"
                    @click="codeSelected('9')"
                    :class="codeIncludes('9') ? 'add' : ''"
                  >
                </span>
              </div>
              <div class="questionform retrievecode">
                <p>{{ $t("retrieve_answer_page.your_code") }}</p>
                <div class="codebox">
                  <span>
                    <img
                      src="../assets/images/blank.png"
                      alt="blank"
                      v-if="codeGenerate.length < 1"
                    >
                    <img
                      :src="codeGenerate[0]"
                      alt="rec"
                      v-if="codeGenerate.length > 0"
                    >
                  </span>
                  <span>
                    <img
                      src="../assets/images/blank.png"
                      alt="blank"
                      v-if="codeGenerate.length < 2"
                    >
                    <img
                      :src="codeGenerate[1]"
                      alt="rec"
                      v-if="codeGenerate.length > 1"
                    >
                  </span>
                  <span>
                    <img
                      src="../assets/images/blank.png"
                      alt="blank"
                      v-if="codeGenerate.length < 3"
                    >
                    <img
                      :src="codeGenerate[2]"
                      alt="rec"
                      v-if="codeGenerate.length > 2"
                    >
                  </span>
                </div>
                <span @click="clearCode()">{{ $t("retrieve_answer_page.clear_code") }}</span>
                <p
                  class="error"
                  v-if="passcodeError"
                  v-html="$t('retrieve_answer_page.invalid_code')"
                />
                <button
                  type="submit"
                  @click="findMyAnswer()"
                >
                  {{ $t("retrieve_answer_page.find_my_answer") }}<i class="fa fa-caret-right" />
                </button>
              </div>
            </div>
            <p>{{ $t("retrieve_answer_page.if_you_have_forgotten") }}</p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      codeGenerate: [],
      passcodeIcons: [],
      passcode: [],
      passcodeError: false,
    };
  },
  methods: {
    codeSelected(value) {
      if (this.codeGenerate.length >= 3) {
        return false;
      }
      this.codeGenerate.push(this.passcodeIcons[value]);
      this.passcode.push(value);
    },
    clearCode() {
      this.codeGenerate = [];
      this.passcode = [];
    },
    codeIncludes(value) {
      if (this.codeGenerate.includes(this.passcodeIcons[value])) {
        return true;
      }
      return false;
    },
    findMyAnswer() {
      if (this.passcode.length < 3) {
        this.passcodeError = true;
        return;
      }

      this.passcodeError = false;

      this.$apiService
        .retrieveAnswer(
          this.initialInfo.currentLanguage.id,
          this.passcode.join("").toLowerCase()
        )
        .then((response) => {
          let res = response.data;

          if (res.data == undefined) {
            res.data = {};
          }

          res.data.passcode = this.passcode.join("").toLowerCase();
          res.data.answer_yet = res.answer_yet;
          res.data.invalid = res.invalid;
          sessionStorage.setItem("retrievePasscode", JSON.stringify(res.data));
          this.$router.push("retrieve");
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
  mounted() {
    this.passcodeIcons = this.$apiService.getPasscodeIcon();
  },
};
</script>