<template>
  <footer v-if="!['redirectPage'].includes($route.name)">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <a
            :href="gynecologyWebsite"
            target="_blank"
          ><img
            src="../assets/images/figo.png"
            alt="figo"
          ></a>
        </div>
        <div class="col-md-9">
          <ul class="footerlinks">
            <li v-if="isEnabled">
              <router-link
                to="/retrieve-an-answer"
                class="nav-link"
              >
                {{
                  $t("global_page.retrieve_an_answer")
                }}
              </router-link>
            </li>
            <li>
              <router-link
                to="/privacy-policy"
                class="nav-link"
              >
                {{
                  $t("global_page.privacy_policy")
                }}
              </router-link>
            </li>
            <li>
              <router-link
                to="/terms-of-use"
                class="nav-link"
              >
                {{
                  $t("global_page.terms_of_use")
                }}
              </router-link>
            </li>
            <li>
              <router-link
                to="/site-map"
                class="nav-link"
              >
                {{
                  $t("global_page.website_site_map")
                }}
              </router-link>
            </li>
            <li>
              <a
                :href="doctorPortalUrl"
                target="_blank"
              >{{ $t("global_page.figo_doctor_portal") }}
                <i class="fa fa-share-square-o" /></a>
            </li>
          </ul>
          <p v-html="$t('global_page.disclaimer')" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      isEnabled: false,
      doctorPortalUrl: "",
      gynecologyWebsite: "",
    };
  },
  mounted() {
    this.doctorPortalUrl = process.env.VUE_APP_DOCTOR_PORTAL_URL;
    this.gynecologyWebsite = process.env.VUE_APP_GYNECOLOGY_WEBSITE_URL;

    this.$eventBus.$on("initial-data-event", (data) => {
      this.isEnabled = data.is_enabled;
    });

    if (this.hasInitialInfo) {
      this.isEnabled = this.initialInfo.is_enabled;
    }
  },
};
</script>