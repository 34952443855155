import Vue from "vue";
import VueI18n from "vue-i18n";

import axios from "axios";
import { EventBus } from "./services/event-bus";
import { page } from "vue-analytics";

Vue.use(VueI18n);
Vue.config.disableNoTranslationWarning = true;

// Fix for empty localized strings
// @url: https://github.com/kazupon/vue-i18n/issues/563
Vue.prototype.$t = function (...args) {
	let result = this.$i18n.t.apply(this.$i18n, args);
	if (result === args[0] || !result || result == "") {
		args[1] = "en";
		// console.warn(
		// 	"Missing localization string",
		// 	args,
		// 	"Resolving to",
		// 	this.$i18n.t.apply(this.$i18n, args),
		// 	this.$i18n.messages
		// );
		result = this.$i18n.t.apply(this.$i18n, args);
		return result;
	}
	return result;
};

const loadingLanguages = {};

const i18n = new VueI18n({
	locale: "en",
	fallbackLocale: "en",
	messages: {},
	silentTranslationWarn: true,
});

// Load the curent locale
function getCurrentLocale () {
	const initialInfoFromSession = sessionStorage.getItem("initialInfo");
	const initialInfo = initialInfoFromSession
		? JSON.parse(initialInfoFromSession)
		: {
			currentLanguage: { locale: "en" },
		};
	return initialInfo.currentLanguage.locale || "en";
}

function setCurrentLocale(locale) {
	i18n.locale = locale;
}

function hasLocaleConfig(locale, page) {
	return page != undefined && (
		(
			i18n.availableLocales.indexOf(locale) > -1 &&
			i18n.messages[locale].hasOwnProperty(page)
		)
	);
}

function setIsLoadingLanguage (locale, page) {
	loadingLanguages[locale] = loadingLanguages[locale] || [];
	loadingLanguages[locale].push(page);
}

function isLoadingLanguage (locale, page) {
	return loadingLanguages.hasOwnProperty(page) && loadingLanguages[locale].indexOf(page) > -1;
}

function stopLoadingLanguage (locale, page) {
	if (isLoadingLanguage(locale, page)) {
		loadingLanguages[locale].splice(loadingLanguages[locale].indexOf(page), 1);
	}
}

EventBus.$on("initial-data-event", (data) => {
	let locale = data instanceof Object && data.currentLanguage ? data.currentLanguage.locale : getCurrentLocale();
	if (!hasLocaleConfig(locale, 'global_page')) {
		let loading = Vue.$loading.show();
		EventBus.$emit("loading-locales", true);

		axios
			.get("config/global/" + locale)
			.then((response) => {
				let res = response.data;
				if (res.success) {
					i18n.locale = locale;
					i18n.mergeLocaleMessage(locale, { global_page: res.data });
					Vue.nextTick(() => {
						EventBus.$emit("page-i18n-data-event", true);
						loading.hide();
					});
				}
			})
			.catch((e) => {
				console.log(e);
				loading.hide();
			});
	} else {
		EventBus.$emit("loading-locales", false);
		setCurrentLocale(locale);
	}
});

EventBus.$on("page-i18n-data-event", (data) => {
	let loading = Vue.$loading.show();
	let locale = getCurrentLocale();

	let currentUrl = window.location.pathname;

	let pageConfig = {};

	if (currentUrl == "/") {
		pageConfig.page = "home";
		pageConfig.name = "home_page";
	}

	if (currentUrl.includes("/learn-from-dr-figo")) {
		pageConfig.page = "exploreallinformation";
		pageConfig.name = "explore_information_page";
	}

	if (currentUrl.includes("/personal-health-basics")) {
		pageConfig.page = "personalhealthbasics";
		pageConfig.name = "personal_health_basics";
	}

	if (currentUrl.includes("retrieve")) {
		pageConfig.page = "retrieveananswer";
		pageConfig.name = "retrieve_answer_page";
	}

	if (currentUrl == "/about-dr-figo") {
		pageConfig.page = "aboutdrfigo";
		pageConfig.name = "about_dr_figo_page";
	}

	if (currentUrl == "/emergency-help") {
		pageConfig.page = "emergencyhelp";
		pageConfig.name = "emergency_help_page";
	}

	if (currentUrl == "/anwser-single-topic" || currentUrl.includes("success")) {
		pageConfig.page = "submitquestion";
		pageConfig.name = "submit_question_page";
	}


	if (
		!hasLocaleConfig(locale, pageConfig.name) &&
		!isLoadingLanguage(locale, pageConfig.name)
	) {
		setIsLoadingLanguage(locale, pageConfig.name);
		EventBus.$emit("loading-locales", true);
		axios
			.get("config/" + pageConfig.page + "/" + locale)
			.then((response) => {
				let res = response.data;
				if (res.success) {
					i18n.mergeLocaleMessage(locale, {
						[pageConfig.name]: res.data,
					});
					Vue.nextTick(() => {
						EventBus.$emit("loading-locales", false);
						setCurrentLocale(locale);
						loading.hide();
					});
				}
				stopLoadingLanguage(locale, pageConfig.name);
			})
			.catch((e) => {
				console.log(e);
				EventBus.$emit("loading-locales", false);
				stopLoadingLanguage(locale, pageConfig.name);
			});
	} else {
		Vue.nextTick(() => {
			EventBus.$emit("loading-locales", false);
			setCurrentLocale(locale);
			loading.hide();
		});
	}
});

export default i18n;
