<template>
  <main
    v-if="!isLoadingLocales"
    class="home"
  >
    <section class="homebanner">
      <div class="container">
        <div class="row">
          <div class=" col-sm-12 col-md-7">
            <div class="bannersearch">
              <img
                src="../assets/images/logo-tag.png"
                alt="AskDr.Figo"
              >
              <div
                v-if="isEnabled"
                class="bannersearch-form"
              >
                <input
                  v-model="askQuestion"
                  type="text"
                  name=""
                  :placeholder="$t('home_page.example_can_i_get_pregnant')"
                  :style="{
                  }"
                  @keyup.enter="saveQuestion"
                >
                <button
                  type="button"
                  @click="saveQuestion()"
                >
                  {{ $t("home_page.search") }} <i class="fa fa-search" />
                </button>
              </div>
              <h1 v-if="isEnabled">
                {{ $t("home_page.dr_figo_can_anonymously") }}
              </h1>
              <p
                v-if="isEnabled"
                class="already-asked"
              >
                <i class="fa fa-play" />
                <span v-html="$t('home_page.already_answer_a_question')" />
                <router-link to="/retrieve-an-answer">
                  {{ $t("home_page.click_here_to_retrieve") }}
                </router-link>
              </p>
              <p
                class="emergency-help"
                @click="sendToEmergencyPage()"
                v-if="isEnabled"
              >
                <b v-html="$t('home_page.if_this_is_an_emergency')" />
              </p>

              <div
                v-if="!isEnabled"
                class="mt-4"
              >
                <h4 v-html="$t('home_page.welcome_to_ask_dr_figo_beta')" />
                <p v-html="$t('home_page.as_of_right_now')" />
              </div>
              <p
                v-if="!isEnabled"
                style="display: inline-flex; align-items: center"
              >
                <i class="fa fa-play" />&nbsp;
                <span v-html="$t('home_page.please_browse_our_question')" />
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <img
              class="bannerhuman"
              src="../assets/images/human.png"
              alt="human"
            >
          </div>
        </div>
      </div>
    </section>

    <section class="whatare">
      <div class="container">
        <div class="row">
          <div class="col-md-4 whatarehuman">
            <img
              src="../assets/images/human2.png"
              alt="human"
            >
          </div>
          <div class="col-md-8 offset-md-4">
            <div class="whataretext">
              <h1>{{ $t("home_page.what_are_other_people") }}</h1>
              <div class="row">
                <div
                  class="col-md-6"
                  v-for="(item, index) in homeFaqList"
                  :key="index"
                >
                  <div class="askingqa">
                    <i class="fa fa-question" />
                    <h2 class="ellipsis-2">
                      {{ item.title }}
                    </h2>
                    <router-link
                      :to="
                        '/learn-from-dr-figo/' +
                          item.categories[0].id +
                          '/learn-topic/' +
                          item.id +
                          '/single-topic?topic=' +
                          item.categories[0].title
                      "
                    >
                      {{ $t("home_page.see_the_answer") }}
                      <i class="fa fa-caret-right" />
                    </router-link>
                  </div>
                </div>
              </div>

              <router-link
                to="/learn-from-dr-figo"
                class="seewhat"
              >
                {{ $t("home_page.see_what_else_people") }}
                <i class="fa fa-caret-right" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="checkout">
      <div class="container">
        <div class="row">
          <div class="col-md-6 offset-md-1">
            <div class="checkouttext">
              <h1>{{ $t("home_page.check_out_basic_information") }}</h1>
              <p v-html="$t('home_page.explore_some_key_topics')" />
              <router-link to="/personal-health-basics">
                {{ $t("home_page.check_out_dr_figo_basics") }}
                <i class="fa fa-caret-right" />
              </router-link>
            </div>
          </div>
          <div class="col-md-5">
            <img
              class="checkouthuman"
              src="../assets/images/human1.png"
              alt="human"
            >
          </div>
        </div>
      </div>
    </section>

    <section class="whatare partners">
      <div class="container">
        <div class="row">
          <div class="col-md-11 offset-md-1">
            <div class="partnershead">
              <h1>{{ $t("home_page.thankyou_to_our_partners") }}</h1>
            </div>
            <div
              class="partnerimg"
              v-if="partners.length > 0"
            >
              <VueSlickCarousel
                :arrows="true"
                :dots="false"
                :slides-to-show="4"
                :slides-to-scroll="4"
                :infinite="false"
              >
                <img
                  v-for="(item, index) in partners"
                  class="custom-hand hover-indicator"
                  :key="index"
                  :src="item.logo"
                  :alt="item.title"
                  @click="openSelectedLink(item)"
                >
              </VueSlickCarousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      isEnabled: false,
      askQuestion: "",
      partners: [],
      homeFaqList: [],
    };
  },
  created() {},
  mounted() {
    this.$eventBus.$on("initial-data-event", (data) => {
      this.isEnabled = data.is_enabled;
      this.getPartners();
      this.getHomeFaqs();
    });

    this.$eventBus.$on("page-data-event", (data) => {
      this.getPartners();
      this.getHomeFaqs();
    });

    if (this.hasInitialInfo) {
      this.isEnabled = this.initialInfo.is_enabled;
      this.getPartners();
      this.getHomeFaqs();
    }
  },
  methods: {
    saveQuestion() {
      if (this.askQuestion.length > 0) {
        sessionStorage.setItem("askQuestion", this.askQuestion);
        this.$router.push("/anwser-single-topic");
      }
    },
    getPartners() {
      this.partners = [];
      this.$apiService
        .getPartners({
          locale: this.currentLocale,
        })
        .then((res) => {
          this.partners = res.data.data;
        });
    },
    openSelectedLink(link) {
      if (link.link.startsWith("http://") || link.link.startsWith("https://")) {
        window.open(link.link, "_blank");
      } else {
        window.open("//" + link.link, "_blank");
      }
    },
    sendToEmergencyPage() {
      this.$router.push("/emergency-help");
    },
    getHomeFaqs() {
      let params = {
        locale: this.currentLocale,
      };

      this.$apiService.getHomeFaqs(params).then((res) => {
        this.homeFaqList = res.data;
      });
    },
  },
  beforeDestroy() {
    this.$eventBus.$off("page-data-event");
  },
};
</script>

<style>
.emergency-help {
  color: rgb(240, 82, 52);
  text-decoration: underline;
  cursor: pointer;
}

.slick-track {
  display: flex !important;
  align-items: center;
}
.slick-slide img {
  height: auto;
  width: auto !important;
  max-height: 150px;
}
</style>
